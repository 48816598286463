<template>
  <div class="form">
    <div class="form_con" id="forms2">
      <h2>获奖公示</h2>
      <div class="list list_one">
        <h3>金奖</h3>
        <ul v-if="list!=null">
          <li v-for="(item,index) in list.first" :key="index" @click="toDetail(item.id)"> 
            <div class="thumb">
              <div class="thumb_img"><img :src="item.thumb" /></div>
             
            </div>
            <div class="title">{{item.title}}</div>
          </li>
        </ul>
        <el-empty v-if="!list || !list.first || list.first.length<=0" description="暂无数据"></el-empty>
      </div>
       <div class="list list_one">
        <h3>银奖</h3>
        <ul v-if="list!=null">
          <li v-for="(item,index) in list.second" :key="index" @click="toDetail(item.id)">
            <div class="thumb">
              <div class="thumb_img"><img :src="item.thumb" /></div>
             
            </div>
            <div class="title">{{item.title}}</div>
          </li>
        </ul>
        <el-empty v-if="!list || !list.second || list.second.length<=0" description="暂无数据"></el-empty>
      </div>
      <div class="list list_two">
        <h3>铜奖</h3>
        <ul v-if="list!=null">
          <li v-for="(item,index) in list.third" :key="index" @click="toDetail(item.id)">
            <div class="thumb">
              <div class="thumb_img"><img :src="item.thumb" /></div>
             
            </div>
            <div class="title">{{item.title}}</div>
          </li>
         
        </ul>
        <el-empty v-if="!list || !list.third || list.third.length<=0" description="暂无数据"></el-empty>
      </div>


      <div class="list list_noborder">
         <h3>优异奖</h3>
        <ul v-if="list!=null">
          <li v-for="(item,index) in list.fourth" :key="index" @click="toDetail(item.id)">
            <div class="thumb">
              <div class="thumb_img"><img :src="item.thumb" /></div>
              
            </div>
            <div class="title">{{item.title}}</div>
          </li>
        </ul>
        <el-empty v-if="!list || !list.fourth || list.fourth.length<=0" description="暂无数据"></el-empty>
      </div>
     
     </div>
  </div>
</template>
<script>
export default {
  name: "matchForm",
  data() {
    return {
      events_id:'',
      list:null
    };
  },
  components:{
  },
  mounted() {
    this.events_id=1;
    this.getList();
    setTimeout(() => {
        var odiv = document.getElementById("forms2");
        let s = this.getDistanceOfLeft(odiv).top;
        document.body.scrollTop = s-100;
        document.documentElement.scrollTop = s-100;
      }, 10);
  },
  methods: {
    getList() {
      this.$api.POST(this.$face.events_ranking, { events_id: this.events_id }, (res) => {
        if(res.code==200){
          this.list=res.data;
        }
      });
    },
    toDetail(id){
      let routeData = this.$router.resolve({
          name: "matchDetail",
          query: {
            id: id
          },
        });
        window.open(routeData.href, "_blank");
    },
    getDistanceOfLeft(obj) {
      var left = 0;
      var top = 0;
      while (obj) {
        left += obj.offsetLeft;
        top += obj.offsetTop;
        obj = obj.offsetParent;
      }
      return {
        left: left,
        top: top,
      };
    }
  },
};
</script>

<style scoped>
.form {
  background: #0000fe;
  overflow: hidden;
}
.form_con {
  width: 1255px;
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 35px 45px;
  margin: 105px auto;
  min-height: 800px;
}
.form_con h2 {
  font-size: 38px;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px #bfbfbf solid;
  margin-bottom: 20px;
}
.form_con {
  overflow: hidden;
}
.list{ padding-top: 30px; width: 100%; overflow: hidden; border-bottom: 1px #dfdfdf solid;padding-bottom: 20px;}
.list li{ width: 365px; height: 340px; float: left; margin:0 11px 22px; overflow: hidden;}
.list li .thumb{ width: 365px; height: 270px; position: relative; background: #f2f2f2; border-radius: 6px; position: relative; overflow: hidden; border-radius: 10px;}
.list li .thumb .thumb_img{position: absolute; top: 50%; transform: translateY(-50%); width: 100%;}
.list li .thumb .thumb_img img{ width: 100%; transition: 0.3s;}
.list li .title{ font-size: 20px; margin-top: 25px; color: #333333;}
.list li .thumb .del,.list li .thumb .edit{ width: 60px; height: 32px; font-size: 16px; color: #fff; text-align: center; line-height: 32px; background: rgba(0,0,0,0.3); border-radius: 6px; display: none; cursor: pointer;}
.list li .thumb .del{ position: absolute; left: 12px; top: 12px;}
.list li .thumb .edit{ position: absolute; right: 12px; top: 12px;}

.list h3{ font-size: 30px; color: #333;padding-left: 22px; margin-bottom: 20px;}
.list_one li{ width: 100%;height: 555px;}
.list_one li .thumb{ width: 100%; height: 555px;}
.list_two li{ width: 553px;height: 268px;}
.list_two li .thumb{ width: 553px; height: 268px;}
.list_noborder{ border-bottom: none;}
</style>